import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ContentWrapper from "../components/content-wrapper"

import Layout from "../components/layout"
import Seo from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ProvacyPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <Seo
        title="Privacidade"
        description="Disponível para todos os esclarecimentos e projetos."
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <ContentWrapper>
            <h1>Política de Privacidade</h1>
            <h2>Resumo</h2>
            <p>
              Usamos algumas funcionalidades de terceiros (google analytics e
              outros) para melhorar o nosso website. Estes serviços podem
              guardar informações sobre o utilizador.
            </p>

            <h2>O que são cookies?</h2>

            <p>
              Cookies são pequenos arquivos armazenados no computador do
              utilizador quando um site é visitado. Podem guardar dados
              específicos relativos a um utilizador e o site visitado, por uma
              duração de tempo específica e podem ser acedidos ​​pelo servidor
              do site ou pelo computador do visitante. Nós utilizamos cookies.
            </p>

            <h2>Google Analytics</h2>
            <p>
              O Google Analytics é usado para perceber o que fazem o utilizador
              no site. As informações recolhidas incluem:
            </p>
            <ul>
              <li>Número de utilizadores e visitas ao site e páginas.</li>
              <li>
                Dados gerais da navagação do utilizador: tempo no site, país,
                browser, etc.
              </li>
              <li>
                Dados de como o utilizador chegou ao site, de onde veio e que
                termos de pesquisa utilizou.
              </li>
            </ul>
            <p>
              Os dados pessoais incluem endereço IP, cookies, dados de
              utilização.
            </p>
            <p>
              Para saber mais, visita os termos e condições do Google Analytics.
            </p>

            <h2>Alojamento do site</h2>
            <p>
              O site é alojado no Netlify e usa alguns serviços que podem
              guardar informações sobre o utilizador.
            </p>
            <p>
              Nomeadamente, os contatos feitos pelo formulário do site serão
              alojados nesta plataforma.
            </p>
            <p>Para saber mais, visita os termos e condições do Netlify.</p>
          </ContentWrapper>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const ElementsStatic = (props) => (
  <StaticQuery
    query={indexQuery}
    render={(data) => (
      <ProvacyPage location={props.location} data={data} {...props} />
    )}
  />
)

export default ElementsStatic
